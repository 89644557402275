import { Options, Vue }                    from "vue-class-component";
import { SessionStorageEnum }              from "@/utils/SessionStorageEnum";

import UsersTable from './UsersTable/UsersTable.vue';
import { UsersRoutesEnum } from "../../router";
import { usersService } from "@services/users.service";

import {
  PageHeader,
} from "@/components";

@Options({
  components: {
    UsersTable,
    PageHeader,
  },
  beforeRouteEnter(to, from, next){
    if(from.name && !(from.name as string).startsWith('users')){
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_USERS);
    }

    next();
  },
  beforeRouteLeave() {
    
    if (usersService?.cancelPendingRequests) {
      
      usersService.cancelPendingRequests();
    }
  },
})
export default class UsersPage extends Vue {
  get newUserRoute(){
    return {
      name: UsersRoutesEnum.USER_DETAIL, 
      params: { userId: 'new' }
    }
  }
}
