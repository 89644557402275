import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      showDelete: false,
      showSave: false,
      title: _ctx.$t('customer.list.title')
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_router_link, { to: _ctx.newCustomerRoute }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              icon: "pi pi-plus",
              label: "Aggiungi"
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_AppTable, {
      filtersSchema: _ctx.filters,
      service: _ctx.service,
      stateKey: _ctx.stateKey,
      onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDetail($event.data))),
      onEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDetail($event)))
    }, {
      columns: _withCtx(({onApplyFilter}) => [
        _createVNode(_component_Column, {
          field: "business_name",
          header: "Azienda",
          sortable: true,
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Azienda inizia per...",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-times",
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-check",
              label: "Applica",
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          header: "Città",
          field: "business_city_name",
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Città inizia per...",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-times",
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-check",
              label: "Applica",
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "business_address",
          header: "Indirizzo",
          sortable: true,
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Indirizzo contiene...",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-times",
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-check",
              label: "Applica",
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "name",
          header: "Name",
          sortable: true,
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Nome inizia per...",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-times",
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-check",
              label: "Applica",
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "surname",
          header: "Surname",
          sortable: true,
          showFilterMatchModes: false
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              placeholder: "Cognome inizia per...",
              onKeyup: _withKeys(($event: any) => (onApplyFilter(filterCallback)), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-times",
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-check",
              label: "Applica",
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_Column, {
          field: "business_tel",
          header: "Telefono"
        })
      ]),
      _: 1
    }, 8, ["filtersSchema", "service", "stateKey"])
  ]))
}