import { Options, Vue }        from "vue-class-component";
import { CustomersRoutesEnum } from "../../router";
import { SessionStorageEnum }  from "@/utils/SessionStorageEnum";
import { customersService }    from "@services/customers.service";
import { FilterMatchMode }     from "primevue/api";
import { Customer }            from "@/model/Customer";

import { AppTable }   from "@sharedComponents";
import { PageHeader } from "@/components";

@Options( {
  components: {
    AppTable,
    PageHeader
  },
  beforeRouteEnter( to, from, next ) {
    if (from.name && !(from.name as string).startsWith( 'customers' )) {
      sessionStorage.removeItem( SessionStorageEnum.TABLE_STATE_CUSTOMERS );
    }

    next();
  },
  beforeRouteLeave() {

    if (this.service?.cancelPendingRequests) {

      this.service.cancelPendingRequests();
    }
  },
})
export default class CustomersPage extends Vue {
  filters: any = null;

  get service() {
    return customersService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_CUSTOMERS
  }

  get newCustomerRoute() {
    return {
      name: CustomersRoutesEnum.CUSTOMERS_DETAIL,
      params: { customerId: 'new' }
    }
  }

  goToDetail(customer: Customer) {
    this.$router.push({
      name: CustomersRoutesEnum.CUSTOMERS_DETAIL,
      params: { customerId: customer.id }
    })
  }

  private initFilter() {
    this.filters = {
      global          : {
        value    : null,
        matchMode: FilterMatchMode.CONTAINS
      },
      name            : {
        value    : null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      surname         : {
        value    : null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      email           : {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_address: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      business_city_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      }
    };
  }

  created() {
    this.initFilter();
  }

}
