import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card page--limited" }
const _hoisted_2 = { class: "p-p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSendMessage = _resolveComponent("ButtonSendMessage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.isNew ? _ctx.$t('customer.detail.new') : _ctx.$t('customer.detail.edit'),
      backRoute: _ctx.backRoute,
      onOnDelete: _ctx.onDelete,
      deleteDisabled: _ctx.isNew
    }, {
      saveButton: _withCtx(() => [
        (_ctx.customer?.id)
          ? (_openBlock(), _createBlock(_component_ButtonSendMessage, {
              key: 0,
              userId: _ctx.customerId
            }, null, 8, ["userId"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_Button, {
          class: "p-mr-1",
          icon: "pi pi-save",
          onClick: _ctx.onSubmit
        }, null, 8, ["onClick"]), [
          [
            _directive_tooltip,
            _ctx.$t('common.save'),
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      _: 1
    }, 8, ["title", "backRoute", "onOnDelete", "deleteDisabled"]),
    _createVNode(_component_TabMenu, { model: _ctx.tabs }, null, 8, ["model"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({Component}) => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_KeepAlive, { exclude: "customer-user" }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              isLoading: !_ctx.customer || _ctx.requestPending,
              customerId: _ctx.customerId,
              customer: _ctx.customer,
              zones: _ctx.zones,
              onFormSubmit: _ctx.onSubmit,
              onReload: _ctx.loadCustomer
            }, null, 8, ["isLoading", "customerId", "customer", "zones", "onFormSubmit", "onReload"]))
          ], 1024))
        ])
      ]),
      _: 1
    })
  ]))
}