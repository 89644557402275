import { Options, Vue }       from "vue-class-component";
import { User, UserRoleEnum } from "@/model/User";
import { usersService }       from "@services/users.service";
import { PrimeVueEvent }      from "@/model/common/PrimeVueTypes";
import { FilterMatchMode }    from "primevue/api";
import { UsersRoutesEnum }    from "../../../router";
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { authStore }          from "@/modules/auth/store";

import { TableSkeleton } from '@sharedComponents';

@Options( {
  components: {
    TableSkeleton,
  }
} )
export default class UsersTable extends Vue {
  firstLoading: boolean = true;
  loading = false;

  users: User[] = null;

  totalRecords = 0;

  filters: any = null;

  get currentUserId() {
    return this.me?.id;
  }

  get currentRole() {
    return authStore.getters.userRole;
  }

  get roleOptions() {
    switch (this.currentRole) {
      case UserRoleEnum.SUPER_ADMIN:
        return [
          { label: 'ADMIN', value: UserRoleEnum.SUPER_ADMIN },
          { label: 'OPERATOR', value: UserRoleEnum.TMT_OPERATOR },
        ];

      case UserRoleEnum.CUSTOMER_ADMIN:
        return [
          { label: 'CUSTOMER ADMIN', value: UserRoleEnum.CUSTOMER_ADMIN },
          { label: 'CUSTOMER USER', value: UserRoleEnum.CUSTOMER_USER },
        ]

      case UserRoleEnum.SUPPLIER_ADMIN:
        return [
          { label: 'SUPPLIER ADMIN', value: UserRoleEnum.SUPPLIER_ADMIN },
          { label: 'SUPPLIER USER', value: UserRoleEnum.SUPPLIER_USER },
        ]
    }
  }

  get dt(): any {
    return this.$refs.dt;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_USERS;
  }

  private async loadData(params?: PrimeVueEvent) {
    params?.originalEvent && delete params.originalEvent;

    try {
      this.loading = true;

      const {
              filters,
              sortField,
              sortOrder
            } = params || {};

      const p = {
        sortField,
        sortOrder,
        per_page: params?.rows,
        page: (params?.first ? params.first / params.rows : 0) + 1,
      } as any;

      if (filters && Object.keys(filters).length) {
        p.filters = { ...filters };
      }

      const response = await usersService.index(p);

      const { data, total } = response;

      this.users = data;
      this.totalRecords = total;

    } catch (error) {
      console.error(error);
      this.$errorMessage("", "Caricamento utenti interroto");
    } finally {
      this.loading = false;
      this.firstLoading = false;
    }
  }

  get filterableColumns() {
    return [
      {
        field: 'name',
        header: 'Nome',
        placeHolder: "Cerca per nome"
      },
      {
        field: 'surname',
        header: 'Cognome',
        placeHolder: "Cerca per cognome"
      },
      {
        field: 'email',
        header: 'Email',
        placeHolder: "Cerca per email"
      },

    ]
  }

  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      surname: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      email: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      role: {
        value: null,
        matchMode: FilterMatchMode.IN
      }
    };
  }

  private getFilters() {
    return {
      ...this.dt.createLazyLoadEvent(),
      filters: this.filters
    }
  }

  onApplyFilter() {
    this.loadData(this.getFilters());
  }

  onClearFilter() {
    this.initFilter();
    this.loadData(this.getFilters());
  }

  onGlobalFilterSubmit() {
    this.loadData(this.getFilters());
  }

  onPage(e: Event | PrimeVueEvent): any {
    const pe = e as PrimeVueEvent;
    this.loadData(pe);
  }

  onSort(e: Event): any {
    const pe = e as PrimeVueEvent;
    this.loadData(pe);
  }

  onRowClick(user: User) {
    this.$router.push({
      name: UsersRoutesEnum.USER_DETAIL,
      params: { userId: user.id }
    });
  }

  onDelete(user: User) {
    const message = this.$t(
      'user.delete_message',
      { name: this.getFullName(user) }
    );

    this.$confirmMessage(message)
      .then(async response => {
        if (response) {
          this.$waitFor(
            async () => {
              await usersService.remove(user);

              this.loadData();

              this.$successMessage("Utente cancellato correttamente");
            },
            "Cancellazione utente non riuscita"
          )
        }
      })

  }

  onEdit(item: User) {
    this.$router.push({
      name: UsersRoutesEnum.USER_DETAIL,
      params: { userId: item.id }
    });
  }

  private getFullName(user: User) {
    return `${user.name} ${user.surname}`;
  }

  created() {
    this.initFilter();
  }

  mounted() {
    this.loadData(this.getFilters());
  }

  errorCaptured(e: Error, v: Vue<UsersTable>, i: string) {
    return true;
  }

}
