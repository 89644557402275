import { Options, Vue }    from "vue-class-component";
import { FilterMatchMode } from "primevue/api";

import { SessionStorageEnum }  from "@/utils/SessionStorageEnum";
import { suppliersService }    from "@services/suppliers.service";
import { SuppliersRoutesEnum } from "@/modules/suppliers/router";
import { Supplier }            from "@/model/Supplier";

import { DynamicTable } from "@sharedComponents";
import { PageHeader }   from "@/components";

@Options( {
  components: {
    DynamicTable,
    PageHeader
  },
  beforeRouteEnter( to, from, next ) {
    if (from.name && !(from.name as string).startsWith( 'suppliers' )) {
      sessionStorage.removeItem( SessionStorageEnum.TABLE_STATE_SUPPLIERS );
    }

    next();
  },
  beforeRouteLeave() {

    if (this.service?.cancelPendingRequests) {

      this.service.cancelPendingRequests();
    }
  }
} )
export default class SuppliersPage extends Vue {
  filters: any = null;

  get service() {
    return suppliersService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_SUPPLIERS
  }

  get newSupplierRoute() {
    return {
      name  : SuppliersRoutesEnum.SUPPLIERS_DETAIL,
      params: { supplierId: 'new' }
    };
  }

  get cols() {
    return [
      {
        field      : 'business_name',
        header     : 'Azienda',
        placeHolder: "Cerca per azienda"
      },
      {
        field      : 'business_city_name',
        header     : 'Città',
        placeHolder: "Cerca per città"
      },
      {
        field      : 'business_address',
        header     : 'Indirizzo',
        placeHolder: "Cerca per indirizzo"
      },
      {
        field      : 'name',
        header     : 'Nome',
        placeHolder: "Cerca per nome"
      },
      {
        field      : 'surname',
        header     : 'Cognome',
        placeHolder: "Cerca per cognome"
      },
      {
        field : 'business_tel',
        header: 'Telefono'
      }
    ]
  }

  goToDetail( supplier: Supplier ) {
    this.$router.push( {
      name  : SuppliersRoutesEnum.SUPPLIERS_DETAIL,
      params: { supplierId: supplier.id }
    } );
  }

  created() {
    this.initFilter();
  }

  private initFilter() {
    this.filters = {
      global          : {
        value    : null,
        matchMode: FilterMatchMode.CONTAINS
      },
      name            : {
        value    : "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      surname         : {
        value    : "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      email           : {
        value    : "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_address: {
        value    : "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_name   : {
        value    : "",
        matchMode: FilterMatchMode.CONTAINS,
      }
    };
  }
}
