import { User }         from "@/model/User";
import { usersService } from "@services/users.service";
import { Options, Vue } from "vue-class-component";
import { Prop }         from "vue-property-decorator";

@Options({
  name: 'supplier-user'
})
export default class SupplierUser extends Vue {
  @Prop() readonly userId! : string;

  isLoading: boolean = true;

  user            : User   = null;
  confirmPassword : string = null;

  get isNew() {
    return this.userId === 'new';
  }

  async loadUser() {
    try {
      this.isLoading = true;

      this.user = await usersService.getById(+this.userId);
    } catch (error) {
      this.$errorMessage("Caricamento utente non riuscito");
    } finally {
      this.isLoading = false;
    }
  }

  private deleteUser() {
    this.$confirm.require({
      message: `Procedere con la cancellazione dell'utente: ${this.user.name} ${this.user.surname} ?`,
      header: 'Conferma cancellazione',
      icon: 'pi pi-info-circle',
      acceptClass: 'p-button-danger',
      accept: () => {
        this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
      },
      reject: () => {
        this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
    });
  }
  
  onSave(): any {
    console.debug("onSave");
  }

  onDelete() {
    this.deleteUser();
  }

  mounted() {
    if (!this.isNew) {
      this.loadUser();
    } else {
      this.user = {};
      this.isLoading = false; 
    }
  }
}