
import { computed, defineComponent, onErrorCaptured, onMounted, provide, ref, } from "vue";

import { useI18n }             from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useForm }             from "vee-validate";

import { SuppliersRoutesEnum } from "../../router";
import { ZoneRelation }        from "@/model/Zone";
import { Error422 }            from "@/model/common/Error422";
import { Supplier }            from "@/model/Supplier";
import { useMessage }          from "@plugins/Messages-plugin";
import { suppliersService }    from "@services/suppliers.service";

import { ButtonSendMessage, PageHeader, SendMessageDialog, } from "@/components";
import { UserRoleEnum }                                      from "@/model/User";
import { entityUsersService }                                from "@services/entity-users.service";

export default defineComponent( {
  name: 'SupplierPage',

  components: {
    PageHeader,
    SendMessageDialog,
    ButtonSendMessage
  },

  props: [ 'supplierId' ],

  setup( props ) {
    const isLoading = ref( false )

    const { confirmMessage, successMessage, errorMessage } = useMessage()

    const { t: $t } = useI18n()

    const route  = useRoute()
    const router = useRouter()

    const loading = ref( false )
    provide( "loading", loading )

    const backRoute = { name: SuppliersRoutesEnum.SUPPLIERS_LIST }

    const isNew = computed( () => props.supplierId === 'new' )

    const supplier = ref<Supplier>()
    const zones    = ref<Array<any>>()

    const tabs = computed<any>( () => [
      {
        label: $t( 'supplier.tabs.data' ),
        icon : 'fas fa-building',
        to   : { name: SuppliersRoutesEnum.SUPPLIERS_DETAIL },
        class: (route.name === SuppliersRoutesEnum.SUPPLIERS_DETAIL) && 'p-highlight'
      },
      {
        label   : $t( 'supplier.tabs.user_list' ),
        icon    : 'fas fa-users',
        to      : { name: SuppliersRoutesEnum.SUPPLIERS_USERS_LIST },
        class   : (route.name as string).startsWith( 'suppliers-users' ) && 'p-highlight',
        disabled: isNew.value
      },
      {
        label   : $t( 'supplier.tabs.zones' ),
        icon    : 'fas fa-map',
        to      : { name: SuppliersRoutesEnum.SUPPLIERS_ZONES },
        class   : route.name === SuppliersRoutesEnum.SUPPLIERS_ZONES && 'p-highlight',
        disabled: isNew.value
      }
    ] )

    const validationSchema = computed( () => ({
      name   : 'required',
      surname: 'required',

      business_name: 'required',
      business_tel : 'required',
      email        : 'required|email',
      pec          : 'required|email',

      business_sdi: null,

      vat        : 'requiredThisOr:fiscal_code',
      fiscal_code: 'requiredThisOr:vat',

      business_city_name  : 'required',
      business_address    : 'required',
      business_postal_code: 'required',
      business_province   : null,
      business_state      : null,

      sdi: null,

      is_active          : null,
      perc_fee           : null,
      cost_avg_delivery  : null,
      is_time_cost_active: null,
      is_ignore_cost_zone: null,
      is_perc_fee_active : null,
    }) )

    const { values, setValues, validate, setFieldTouched } = useForm( { validationSchema } )

    const onSubmit = async () => {
      const { valid } = await validate()
      if (!valid) {
        Object.keys( values ).forEach( key => setFieldTouched( key, true ) )
        errorMessage( $t( 'common.required_fields' ) );
        return
      }

      supplier.value = {
        ...supplier.value,
        ...values,
        shift_costs        : supplier.value.shift_costs,
        vehicle_data       : supplier.value.vehicle_data,
        zones              : zones.value,
        zone_custom_pricing: zones.value
                                  .map( z => z.custom )
                                  .filter( x => x ),
      }

      let response;

      if (supplier.value.id) {
        delete supplier.value.users;
        response = await suppliersService.updatePatch( supplier.value );
      } else {
        response = await suppliersService.create( supplier.value );

        router.replace( {
          name  : SuppliersRoutesEnum.SUPPLIERS_DETAIL,
          params: { supplierId: response.id }
        } );
      }


      if (isNew.value) {
        // Se non ci sono utenti, creo l'utente di default copiando il referente
        const { name, surname, password, email } = supplier.value;

        await entityUsersService.create( {
          entity_id: response.id,
          name, surname, email, password,
          role     : UserRoleEnum.SUPPLIER_ADMIN,
          is_active: true,
        } )
      }

      loadSupplier()

      successMessage( $t( 'message.success_message' ) );
    }

    function onDelete() {
      const { name, surname } = supplier.value;

      confirmMessage(
          `Confermare la cancellazione del cliente: ${ name } ${ surname } ?`,
          "Cancellazione cliente"
      ).then( async ( response ) => {
        if (response) {
          await suppliersService.remove( supplier.value );

          router
              .replace( { name: SuppliersRoutesEnum.SUPPLIERS_LIST } )
              .then( () => {
                successMessage( $t( 'message.success_message' ) );
              } );
        }
      } )
    }


    async function loadSupplier() {
      try {
        supplier.value = await suppliersService.getById( +props.supplierId )
      } catch (error) {
        errorMessage( "Caricamento fornitore non riuscito" )
      } finally {
        isLoading.value = false
      }
    }

    function initFormValues() {
      setValues( {
        ...supplier.value
      } )
    }

    function handle422( { errors }: Error422 ) {
      const fieldsInError = Object.keys( errors )

      function flattenString( a: string[] ) {
        return a?.join( " " )
      }

      if (fieldsInError?.length) {
        fieldsInError.forEach( k => {
          errorMessage(
              flattenString( errors[k] )
          )
        } )
      }
    }

    function getCustomPricingForZone( zone: ZoneRelation ) {
      return supplier.value?.zone_custom_pricing?.find(
          x => x.zone_id === zone.id
      );
    }

    onErrorCaptured( ( e: any ) => {
      if (e.status === 422) {
        handle422( e.data );
        return false
      }
    } )

    onMounted( async () => {
      isLoading.value = true;

      if (isNew.value) {
        supplier.value  = new Supplier();
        isLoading.value = false;
      } else {
        await loadSupplier();
      }

      initFormValues()

      zones.value = supplier.value?.zones?.map( z => {
        return {
          ...z,
          custom: getCustomPricingForZone( z )
        }
      } ) || [];
    } )

    return {
      supplier,
      loadSupplier,
      zones,
      backRoute,
      isNew,
      tabs,
      isLoading,
      onSubmit,
      onDelete
    }

  }
} )
