<template>
  <div class="card page--limited">

    <PageHeader
      :title="isNew ? $t('user.detail.new') : $t('user.detail.edit')"
      :backRoute="backRoute"
      @onSave="onSave"
      @onDelete="onDelete"
      :deleteDisabled="isNew"
      :saveDisabled="requestPending"
    >
      <template #saveButton>
        <Button
          type="submit"
          icon="pi pi-save"
          class="p-mr-1"
          form="userForm"
          v-tooltip.bottom="$t('common.save')"
        />
      </template>
    </PageHeader>

    <!-- SKELETON -->
    <template v-if="requestPending && !user">
      <Fieldset class="p-fluid">
        <template #legend>
          <i class="pi pi-user" />
        </template>
        <Skeleton
          v-for="x in 4"
          :key="`user_${x}`"
          height="2rem"
          class="p-mb-2"
        />
        <Skeleton
          height="2rem"
          width="4rem"
          class="p-mb-2"
        />
      </Fieldset>

      <Divider />

      <Fieldset class="p-fluid">
        <template #legend>
          <i class="pi pi-key" />
        </template>
        <Skeleton
          height="2rem"
          class="p-mb-2"
        />
        <Skeleton
          height="2rem"
          class="p-mb-2"
        />
      </Fieldset>

      <Divider />

      <Fieldset>
        <template #legend>
          <i class="pi pi-map" />
        </template>

        <Skeleton
          height="2rem"
          class="p-mb-2"
        />
        <TableSkeleton
          :rows="3"
          :columns="3"
        />

      </Fieldset>

    </template>

    <!-- REAL FORM -->
    <template v-else>

      <UserForm
        formId="userForm"
        :isLoading="requestPending"
        :roleOptions="roleOptions"
        v-model="user"
        @formSubmit="onSave"
        :isNew="isNew"
      >
        <template v-if="isOperator">
          <Fieldset class="p-mt-3" >

            <template #legend>
              <i class="pi pi-map" />
            </template>

            <ZoneAutocompleteSelect @selectedZones="addZones" />

            <DataTable :value="operatorZones">
              <Column
                header="Nome"
                field="name"
              />
              <Column style="width: 120px;">
                <template #body="{data}">
                  <div class="action_column">
                    <Button
                      icon="pi pi-times"
                      class="p-button-danger"
                      v-tooltip.bottom="$t('remove')"
                      @click="onRemoveZone(data)"
                    />
                  </div>
                </template>
              </Column>
            </DataTable>
          </Fieldset>
        </template>
      </UserForm>
    </template>
  </div>
</template>

<script src="./UserPage.ts"/>
