
import { computed, defineComponent, onErrorCaptured, onMounted, provide, ref } from "vue";

import { useI18n }             from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useForm }             from "vee-validate";

import { CustomersRoutesEnum } from "../../router";
import { ZoneRelation }        from "@/model/Zone";
import { Error422 }            from "@/model/common/Error422";
import { Customer }            from "@/model/Customer";
import { useMessage }          from "@plugins/Messages-plugin";
import { customersService }    from "@services/customers.service";

import { ButtonSendMessage, PageHeader, SendMessageDialog, } from "@/components";
import { UserRoleEnum }                                      from "@/model/User";
import { entityUsersService }                                from "@services/entity-users.service";
import { CUSTOMER_VALIDATION_SCHEMA }                        from "@/modules/common/validation_schemas";

export default defineComponent( {
  name: 'CustomerPage',

  components: {
    PageHeader,
    SendMessageDialog,
    ButtonSendMessage
  },

  props: [ 'customerId' ],

  setup( props ) {
    const isLoading = ref( false )

    const { confirmMessage, successMessage, errorMessage } = useMessage()

    const { t: $t } = useI18n()

    const route  = useRoute()
    const router = useRouter()

    const loading = ref( false )
    provide( "loading", loading )

    const backRoute = { name: CustomersRoutesEnum.CUSTOMERS_LIST }

    const isNew = computed( () => props.customerId === 'new' )

    const customer = ref<Customer>()
    const zones    = ref<Array<any>>()

    const tabs = computed<any>( () => [
      {
        label: $t( 'customer.tabs.data' ),
        icon : 'fas fa-id-card',
        to   : { name: CustomersRoutesEnum.CUSTOMERS_DETAIL },
        class: route.name === CustomersRoutesEnum.CUSTOMERS_DETAIL ? 'p-highlight' : '',
      },
      {
        label   : $t( 'customer.tabs.user_list' ),
        icon    : 'fas fa-users',
        to      : { name: CustomersRoutesEnum.CUSTOMERS_USERS_LIST },
        class   : (route.name as string).startsWith( 'customers-users' ) ? 'p-highlight' : '',
        disabled: isNew.value
      },
      {
        label   : $t( 'customer.tabs.zones' ),
        icon    : 'fas fa-map',
        to      : { name: CustomersRoutesEnum.CUSTOMERS_ZONES },
        class   : route.name === CustomersRoutesEnum.CUSTOMERS_ZONES ? 'p-highlight' : '',
        disabled: isNew.value
      },
      {
        label   : $t( 'customer.tabs.rubric' ),
        icon    : 'fas fa-address-book',
        to      : { name: CustomersRoutesEnum.CUSTOMERS_RUBRIC },
        class   : route.name === CustomersRoutesEnum.CUSTOMERS_RUBRIC ? 'p-highlight' : '',
        disabled: isNew.value
      }
    ] )

    const validationSchema = computed( () => ({
      ...CUSTOMER_VALIDATION_SCHEMA
    }) )

    const { values, setValues, validate, setFieldTouched } = useForm( { validationSchema } )

    const onSubmit = async () => {
      const { valid } = await validate()
      if (!valid) {
        Object.keys( values ).forEach( key => setFieldTouched( key, true ) )
        errorMessage( $t( 'common.required_fields' ) );
        return
      }

      customer.value = {
        ...customer.value,
        ...values,
        vehicle_data       : customer.value.vehicle_data,
        zones              : zones.value,
        zone_custom_pricing: zones.value
                                  .map( z => z.custom )
                                  .filter( x => x ),
      }

      let response;

      if (customer.value.id) {
        delete customer.value.users;
        response = await customersService.updatePatch( customer.value );
      } else {
        response = await customersService.create( customer.value );
        router.replace( {
          name  : CustomersRoutesEnum.CUSTOMERS_DETAIL,
          params: { customerId: response.id }
        } );
      }


      if (isNew.value) {
        // Se non ci sono utenti, creo l'utente di default copiando il referente
        const { name, surname, password, email } = customer.value;

        await entityUsersService.create( {
          entity_id: response.id,
          name, surname, email, password,
          role     : UserRoleEnum.CUSTOMER_ADMIN,
          is_active: true,
        } )
      }

      loadCustomer()

      successMessage( $t( 'message.success_message' ) );
    };

    function onDelete() {
      const { name, surname } = customer.value;

      confirmMessage(
          `Confermare la cancellazione del cliente: ${ name } ${ surname } ?`,
          "Cancellazione cliente"
      ).then( async ( response ) => {
        if (response) {
          await customersService.remove( customer.value );

          router
              .replace( { name: CustomersRoutesEnum.CUSTOMERS_LIST } )
              .then( () => {
                successMessage( $t( 'message.success_message' ) );
              } );
        }
      } )
    }


    async function loadCustomer() {
      try {
        customer.value = await customersService.getById( +props.customerId )
      } catch (error) {
        errorMessage( "Caricamento cliente non riuscito" )
      } finally {
        isLoading.value = false
      }
    }

    function initFormValues() {
      setValues( {
        ...customer.value
      } )
    }

    function handle422( { errors }: Error422 ) {
      const fieldsInError = Object.keys( errors )

      function flattenString( a: string[] ) {
        return a?.join( " " )
      }

      if (fieldsInError?.length) {
        fieldsInError.forEach( k => {
          errorMessage(
              flattenString( errors[k] )
          )
        } )
      }
    }

    function getCustomPricingForZone( zone: ZoneRelation ) {
      return customer.value?.zone_custom_pricing?.find(
          x => x.zone_id === zone.id
      );
    }

    onErrorCaptured( ( e: any ) => {
      if (e.status === 422) {
        handle422( e.data );
        return false
      }
    } )

    onMounted( async () => {
      isLoading.value = true;

      if (isNew.value) {
        customer.value  = new Customer();
        isLoading.value = false;
      } else {
        await loadCustomer();
      }

      initFormValues()

      zones.value = customer.value?.zones?.map( z => {
        return {
          ...z,
          custom: getCustomPricingForZone( z )
        }
      } ) || [];
    } )

    return {
      customer,
      loadCustomer,
      zones,
      backRoute,
      isNew,
      tabs,
      isLoading,
      onSubmit,
      onDelete
    }

  }
} )
