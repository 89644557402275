import { FilterMatchMode } from "primevue/api";
import { Options, Vue }    from "vue-class-component";
import { Prop }            from "vue-property-decorator";

import { EntityUserDialog }   from "@/components";
import { AppTable }           from "@sharedComponents";
import { Customer }           from "@/model/Customer";
import { User, UserRoleEnum } from "@/model/User";
import { entityUsersService } from "@services/entity-users.service";


@Options( {
    name        : "CustomerUsers",
    inheritAttrs: false,
    emits       : [ 'save' ],
    components  : {
        AppTable,
        EntityUserDialog
    }
} )
export default class CustomerUsers extends Vue {
    @Prop()
    readonly customerId!: string;

    @Prop()
    readonly customer!: Customer;

    showUserDialog: boolean = false;

    editUser = new User();

    filters: any = {
        global : {
            value    : null,
            matchMode: FilterMatchMode.CONTAINS
        },
        name   : {
            value    : null,
            matchMode: FilterMatchMode.CONTAINS
        },
        surname: {
            value    : null,
            matchMode: FilterMatchMode.CONTAINS
        },
        email  : {
            value    : null,
            matchMode: FilterMatchMode.CONTAINS
        },
    }

    clearFilters() {
        this.filters.global.value  = null;
        this.filters.name.value    = null;
        this.filters.surname.value = null;
        this.filters.email.value   = null;
    }

    get users() {
        return this.customer?.users || [];
    }

    get roleOptions() {
        return [
            { label: 'Cliente Admin', value: UserRoleEnum.CUSTOMER_ADMIN },
            { label: 'Cliente Operatore', value: UserRoleEnum.CUSTOMER_USER },
        ];
    }

    onSavedUser() {
        this.resetDialog();
        this.hideDialog();

        this.$emit( 'reload' );

        this.$successMessage( 'Utente salvato con successo' );
    }

    onNewUser() {
        this.editUser = new User();
        this.showDialog();
    }

    onEditUser( user: User ) {
        this.editUser = { ...user };
        this.showDialog();
    }

    onRemoveUser( user: User ) {
        this.removeUser( user );
    }


    private showDialog() {
        this.showUserDialog = true;
    }

    private hideDialog() {
        this.showUserDialog = false;
    }

    private resetDialog() {
        this.editUser = new User();
    }

    private async removeUser( user ) {
        const header = this.$t( 'message.confirm_delete' );

        const message = this.$t(
            'user.delete_message',
            { name: `${ user.name } ${ user.surname }` }
        );

        const response = await this.$confirmMessage( message, header )

        if (response) {
            this.$waitFor( async () => {
                await entityUsersService.remove( user )
                const idx = this.customer.users.indexOf( user )
                this.customer.users.splice( idx, 1 );
            }, 'Cancellazione utente non riuscita' );
        }
    }
}
