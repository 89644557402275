import { Options, Vue } from "vue-class-component";
import { Prop }         from "vue-property-decorator";

import { UsersRoutesEnum }    from "../../router";
import { usersService }       from "@services/users.service";
import { authStore }          from "@/modules/auth/store";
import { User, UserRoleEnum } from "@/model/User";
import { Zone }               from "@/model/Zone";

import { PageHeader, UserForm, ZoneAutocompleteSelect } from "@/components";

import { TableSkeleton, } from "@sharedComponents";

@Options( {
    name      : 'UserPage',
    components: {
        PageHeader,
        TableSkeleton,
        UserForm,
        ZoneAutocompleteSelect
    }
} )
export default class UserPage extends Vue {
    @Prop() readonly userId!: string;

    user: User              = null;
    confirmPassword: string = null;

    get currentRole() {
        return authStore.getters.userRole;
    }

    get roleOptions() {

        switch (this.currentRole) {
            case UserRoleEnum.SUPER_ADMIN:
                return [
                    { label: 'Amministratore', value: UserRoleEnum.SUPER_ADMIN },
                    { label: 'TMT operatore', value: UserRoleEnum.TMT_OPERATOR },
                ]

            case UserRoleEnum.CUSTOMER_ADMIN:
                return [
                    { label: 'Cliente Admin', value: UserRoleEnum.CUSTOMER_ADMIN },
                    { label: 'Cliente Operatore', value: UserRoleEnum.CUSTOMER_USER },
                ]

            case UserRoleEnum.SUPPLIER_ADMIN:
                return [
                    { label: 'Fornitore Admin', value: UserRoleEnum.SUPPLIER_ADMIN },
                    { label: 'Fornitore Operatore', value: UserRoleEnum.SUPPLIER_USER },
                ]
        }

    }

    get isNew() {
        return this.userId === 'new';
    }

    get isOperator() {
        return this.user?.role === UserRoleEnum.TMT_OPERATOR;
    }

    get backRoute() {
        return { name: UsersRoutesEnum.USERS_LIST };
    }

    get operatorZones() {
        return this.user?.zones;
    }

    addZones( zones: Zone[] ) {
        this.user.zones = [
            ...(this.user.zones || []),
            ...zones
        ]
    }

    onRemoveZone( zone: Zone ) {
        this.$confirmMessage( "Rimuovere la città dall'utente ?" )
            .then( r => {
                if (r) {
                    this.removeZone( zone );
                }
            } )
    }

    removeZone( zone: Zone ) {
        const idx = this.user.zones?.indexOf( zone );
        this.user.zones?.splice( idx, 1 );
    }

    async loadUser() {
        this.$waitFor(
            async () => {
                this.user = await usersService.getById( +this.userId )
            }
        );
    }

    async onSave(): Promise<any> {
        return this.$waitFor(
            async () => {
                let response: Promise<User>;

                if (!this.isNew) {
                    response = usersService.updatePatch( { id: +this.userId, ...this.user } );
                } else {
                    response = usersService.create( this.user );
                }

                response.then( async user => {
                    await this.$router.replace( {
                        name: UsersRoutesEnum.USERS_LIST,
                    } );

                    this.$successMessage( this.$t( 'message.success_message' ) );
                } )
            }
        );

    }

    async onDelete() {
        const message  = this.$t(
            'user.delete_message',
            { name: `${ this.user.name } ${ this.user.surname }` }
        );
        const header   = this.$t( 'message.confirm_delete' );
        const response = await this.$confirmMessage( message, header )

        const doDelete = async () => {

        }

        if (response) {
            return this.$waitFor( async () => {
                const response = await usersService.remove( { id: +this.userId } );
                this.$router
                    .replace( this.backRoute )
                    .then( () => this.$successMessage( "Utente cancellato correttamente" ) );
            } );
        }
    }

    mounted() {
        if (!this.isNew) {
            this.loadUser();
        } else {
            this.user = new User();
        }
    }
}
