import { Options, Vue }                   from "vue-class-component";
import { Prop }                           from "vue-property-decorator";
import { Customer }                       from "@/model/Customer";
import { EntityPicker, EntityPickerType } from "@/model/Entity";

import { EntityPickerDialog }   from "@/components";
import { entityPickersService } from "@services/entityPickers.service";
import { PrimeVueEvent } from "@/model/common/PrimeVueTypes";
import { FilterMatchMode } from "primevue/api";

@Options({
  inheritAttrs: false,
  components: {
    EntityPickerDialog
  }
})
export default class CustomerRubric extends Vue {
  readonly stateKey: string = "customer-rubric";

  @Prop() readonly customer!: Customer;

  get customerId() {
    return this.customer?.id || this.$route.params.customerId;
  }

  totalRecords = 0;

  isLoading: boolean = false;

  editPicker: EntityPicker = null;

  displayNewPicker: boolean = false;
  onUpdateVisible(value){
    if(!value){
      this.editPicker = null; 
    }
  }
  
  get dt(): any {
    return this.$refs.dt;
  }

  pickers: EntityPicker[] = null; 
  
  onPage(e: any): any {
    const pe = e as PrimeVueEvent;

    this.loadData(pe);
  }

  onSort(e: Event): any {
      const pe = e as PrimeVueEvent;
      this.loadData(pe);
  }

  showNewPicker() {
    this.editPicker = null; 
    this.displayNewPicker = true;
  }

  private async loadData(params?: PrimeVueEvent) {
    params?.originalEvent && delete params.originalEvent;

    try {
        this.isLoading = true;

        const {
            filters,
            sortField,
            sortOrder
        } = params || {};

        const p = {
            entity_id: this.customerId,
            sortField,
            sortOrder,
            per_page : params?.rows,
            page     : (params?.first ? params.first / params.rows : 0) + 1,
        } as any;

        if (filters && Object.keys(filters).length) {
            p.filters = {...filters};
        }

        const response = await entityPickersService.index(p);

        const { data, total } = response;

        this.pickers      = data;
        this.totalRecords = total;

    } catch (error: any) {
        console.error(error);
        this.$errorMessage("", "Dataset loading aborted");

    } finally {
      this.isLoading      = false;
    }
  }

  onEditPicker(data: EntityPicker) {
    this.editPicker = { ...data };
    this.displayNewPicker = true;
  }

  onClonePicker(data: EntityPicker) {
    this.editPicker = { ...data, id: null };
    this.displayNewPicker = true;
  }

  onDeletePicker(data: EntityPicker) {
    const type = data.type === EntityPickerType.DROPOFF 
      ? "consegna" 
      : "ritiro";

    const message = `Rimuovere il punto di ${type} selezionato ?`;

    this.$confirmMessage(message)
      .then(async response => {
        if (response) {
          
          this.$waitFor(
            async () => {
              await entityPickersService.remove(data);

              this.loadData();

              this.$successMessage(`Punto di ${type} eliminato`);
            },

            `Cancellazione punto di ${type} non riuscita`
          )
          
        }
      })
  }
  
  onSaved() {
    this.loadData();
  }

  readonly typeOptions = [
    { label: "Ritiro",    value: EntityPickerType.PICKUP },
    { label: "Consegna",  value: EntityPickerType.DROPOFF }
  ]

  filters: any = null;

  private initFilters() {
    this.filters = {
      name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      type: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      address: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
    }; 
  }

  clearFilters() {
    this.initFilters();
    this.applyFilter();
  }

  private getFilters() {
    return {
        ...this.dt.createLazyLoadEvent(),
        filters : this.filters
    }
  }
  
  onApplyFilter(filterCallback?: Function) {
    filterCallback && filterCallback();
    this.applyFilter();
  }
  
  public applyFilter() {
    this.loadData(this.getFilters());
  }

  created() {
    this.initFilters();
  }

  mounted() {
    this.applyFilter();
  }

}