import { Options, Vue }            from "vue-class-component";
import { Prop }                    from "vue-property-decorator";

import {
  ZoneAutocompleteSelect,
  ZonePricingGrid,
  ZoneVehicleData,
  ZoneShiftCosts
} from "@/components";

import { Supplier }                from "@/model/Supplier";
import { Zone, ZoneCustomPricing } from "@/model/Zone";
import { SessionStorageEnum }      from "@/utils/SessionStorageEnum";

@Options({
  inheritAttrs: false,
  components: {
    ZoneAutocompleteSelect,
    ZonePricingGrid,
    ZoneShiftCosts,
    ZoneVehicleData
  }
})
export default class SupplierZones extends Vue {
  @Prop() readonly supplier! : Supplier;

  @Prop({ default: () => [] }) 
  readonly zones! : Zone[];
  
  get hasZones() {
    return !!this.zones?.length;
  }

  expandedRows: any = [];

  shapePricingConfig = [
    {
      title      : 'Area #1',
      costLabel  : 'Costo',
      costKey    : 'cost_shape1'
    },
    {
      title      : 'Area #2',
      costLabel  : 'Costo',
      costKey    : 'cost_shape2'
    },
    {
      title      : 'Area #3',
      costLabel  : 'Costo',
      costKey    : 'cost_shape3'
    },
    {
      title      : 'Area #4',
      costLabel  : 'Costo',
      costKey    : 'cost_shape4'
    },
    {
      title      : 'Area #5',
      costLabel  : 'Costo',
      costKey    : 'cost_shape5'
    },

  ];

  get stateKey(){
    return SessionStorageEnum.TABLE_STATE_SUPPLIER_DETAIL; 
  }
  
  get selectedIds() {
    return this.zones?.map(z => z.id) || [];
  }

  addSelectedZones(selectedZones){    
    this.zones.push(...selectedZones);
  }


  createCustom(data){
    const keys = [  
      "cost_shape1",
      "cost_shape2",
      "cost_shape3",
      "cost_shape4",
      "cost_shape5",
      "cost_km_flat",
    ];

    const custom: ZoneCustomPricing = {
      zone_id:  data.id
    };

    keys.forEach(k => {
      custom[k] = data[k]
    });

    data.custom = custom;
  }

  restoreStandard(data) {
    delete data.custom; 
  }

  removeZone(data){
    const idx = this.zones.indexOf(data);
    this.zones.splice(idx, 1);
  }

}