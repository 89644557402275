import { Options, Vue }            from "vue-class-component";
import { Prop }                    from "vue-property-decorator";

import { Zone, ZoneCustomPricing } from "@/model/Zone";

import { SessionStorageEnum }      from "@/utils/SessionStorageEnum";

import {
  ZoneAutocompleteSelect,
  ZonePricingGrid,
  ZoneVehicleData
} from "@/components";
import { Customer } from "@/model/Customer";

@Options({
  inheritAttrs: false,
  components: {
    ZoneAutocompleteSelect,
    ZonePricingGrid,
    ZoneVehicleData
  }
})
export default class CustomerZones extends Vue {
  @Prop() readonly customer!: Customer; 
  
  @Prop({ default: () => [] })
  readonly zones!: Zone[];

  get hasZones() {
    return !!this.zones?.length;
  }

  expandedRows: any = [];

  shapePricingConfig = [
    {
      title      : 'Area #1',
      priceLabel : 'Prezzo',
      priceKey   : 'price_shape1',
    },
    {
      title      : 'Area #2',
      priceLabel : 'Prezzo',
      priceKey   : 'price_shape2',
    },
    {
      title      : 'Area #3',
      priceLabel : 'Prezzo',
      priceKey   : 'price_shape3',
    },
    {
      title      : 'Area #4',
      priceLabel : 'Prezzo',
      priceKey   : 'price_shape4',
    },
    {
      title      : 'Area #5',
      priceLabel : 'Prezzo',
      priceKey   : 'price_shape5',
    },

  ];

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_CUSTOMER_DETAIL;
  }

  get selectedIds() {
    return this.zones?.map(z => z.id) || [];
  }

  addSelectedZones(selectedZones) {
    this.zones.push(...selectedZones);
  }

  createCustom(data) {
    const keys = [
      "price_shape1",
      "price_shape2",
      "price_shape3",
      "price_shape4",
      "price_shape5",
      "price_km_flat",
    ];

    const custom: ZoneCustomPricing = {
      zone_id: data.id
    };

    keys.forEach(k => {
      custom[k] = data[k]
    });

    data.custom = custom;
  }

  restoreStandard(data) {
    delete data.custom; 
  }

  removeZone(data) {
    const idx = this.zones.indexOf(data);
    this.zones.splice(idx, 1);
  }
}


