import { Entity, PaymentType, UserType } from "./Entity";

export class Customer extends Entity {
    password?: string; 
    confirmPassword?: string; 
    
    constructor(){
        super()
    }
}

export class RegCustomer {
    name     : string;
    surname  : string;
    email    : string;
    password : string;
    entity   : RegEntity;
}

export class RegEntity {
    name     : string;
    surname  : string;
    business_name        : string;
    business_address     : string;
    business_city_name   : string;
    business_city_id     : string;
    business_province    : string;
    business_state       : string;
    business_postal_code : string;
    business_tel         : string;
    business_note        : string;
    sdi                  : string;
    pec                  : string;
    email                : string;
    vat                  : string;
    fiscal_code          : string;
    type                 : UserType;
    payment_type         : PaymentType;
}