import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card page--limited" }
const _hoisted_2 = { class: "p-p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSendMessage = _resolveComponent("ButtonSendMessage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      backRoute: _ctx.backRoute,
      deleteDisabled: _ctx.isNew,
      title: _ctx.$t(_ctx.isNew ? 'suppliers.new.title' : 'suppliers.detail.title'),
      onOnDelete: _ctx.onDelete
    }, {
      saveButton: _withCtx(() => [
        (_ctx.supplier?.id)
          ? (_openBlock(), _createBlock(_component_ButtonSendMessage, {
              key: 0,
              userId: _ctx.supplierId
            }, null, 8, ["userId"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_Button, {
          class: "p-mr-1",
          icon: "pi pi-save",
          onClick: _ctx.onSubmit
        }, null, 8, ["onClick"]), [
          [
            _directive_tooltip,
            _ctx.$t('common.save'),
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      _: 1
    }, 8, ["backRoute", "deleteDisabled", "title", "onOnDelete"]),
    _createVNode(_component_TabMenu, { model: _ctx.tabs }, null, 8, ["model"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({Component}) => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_KeepAlive, { exclude: "supplier-user" }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              isLoading: !_ctx.supplier || _ctx.requestPending,
              supplierId: _ctx.supplierId,
              supplier: _ctx.supplier,
              zones: _ctx.zones,
              onReload: _ctx.loadSupplier,
              onFormSubmit: _ctx.onSubmit
            }, null, 8, ["isLoading", "supplierId", "supplier", "zones", "onReload", "onFormSubmit"]))
          ], 1024))
        ])
      ]),
      _: 1
    })
  ]))
}